import Rest from './Rest';


class Plants extends Rest {
  getPlants() {
    let restUrl = Rest.BaseUrl + '/plants';
    const requestOptions = {
      headers: {'Authorization': `Bearer ${Rest.Token}`},
    };
    return fetch(restUrl, requestOptions).then(res => res.json());
  }
}

let plants = new Plants();
export default plants;
