import Rest from '../rest/Rest';


class Forestry extends Rest {
  getStock() {
    let restUrl = Rest.BaseUrl + '/forestry/stock';
    const requestOptions = {
      headers: {'Authorization': `Bearer ${Rest.Token}`},
    };
    return fetch(restUrl, requestOptions).then(res => res.json());
  }
  
  
  getOrders() {
    let restUrl = Rest.BaseUrl + '/forestry/orders';
    const requestOptions = {
      headers: {'Authorization': `Bearer ${Rest.Token}`},
    };
    return fetch(restUrl, requestOptions).then(res => res.json());
  }
  
  
  putOrders(orders) {
    let restUrl = Rest.BaseUrl + '/forestry/orders';
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Rest.Token}`
      },
      body: JSON.stringify(orders)
    };
    return fetch(restUrl, requestOptions)
  }
}


let forestry = new Forestry();
export default forestry;
