import Rest from '../rest/Rest';


class Contracts extends Rest {
  getContracts() {
    let restUrl = Rest.BaseUrl + '/contracts';
    const requestOptions = {
      headers: {'Authorization': `Bearer ${Rest.Token}`},
    };
    return fetch(restUrl, requestOptions).then(res => res.json());
  }

  
  // putFarms(farms) {
  //   let restUrl = Rest.BaseUrl + '/farms';
  //   const requestOptions = {
  //     method: 'PUT',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${Rest.Token}`
  //     },
  //     body: JSON.stringify(farms)
  //   };
  //   return fetch(restUrl, requestOptions)
  // }
}


let farm = new Contracts();
export default farm;
