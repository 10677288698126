import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Fab,
  Slide
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function LoginDialog(props) {
  const classes = loginDialogStyles();
  const {onClose, open} = props;
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  
  const onOk = () => {
    onClose(user, password);
  };
  
  return (
    <Dialog
      onClose={onOk}
      aria-labelledby="dialog-title"
      TransitionComponent={Transition}
      open={open}
      fullWidth={false}
      maxWidth={"md"}
    >
      <DialogTitle id="dialog-title">Anmelden</DialogTitle>
      <DialogContent>
        <Grid container justify="center" direction="column" alignItems="center" spacing={2}>
          <Grid key="edit" item>
            <form className={classes.editControl} noValidate autoComplete="off">
              <TextField
                id="edit-user"
                label="User"
                variant="outlined"
                value={user}
                type="text"
                onChange={event => setUser(event.target.value)}
              />
            </form>
          </Grid>
          <Grid key="amount" item>
            <form className={classes.editControl} noValidate autoComplete="off">
              <TextField
                id="edit-password"
                label="Passwort"
                variant="outlined"
                value={password}
                type="password"
                onChange={event => setPassword(event.target.value)}
              />
            </form>
          </Grid>
          <Grid key="save" item>
            <Fab color='secondary' className={classes.okButton} onClick={onOk}><CheckIcon/></Fab>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}


const loginDialogStyles = makeStyles(theme => ({
  okButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  cancelButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  editControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));


LoginDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
