import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import { Alert, AlertTitle } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import {Fab, IconButton} from "@material-ui/core";

import {List, ListItem, ListItemText, ListItemSecondaryAction} from '@material-ui/core';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';

import SaveIcon from "@material-ui/icons/Save";
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import Contracts from './Contracts'
import Plants from '../rest/Plants'

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
  alertMsg: {
    marginTop: theme.spacing(0),
  },
}));


// function SelectProduct(props) {
//   const {classes, plants, farmIdx, selectedPid, onChange} = props;
//
//   return (
//     <FormControl variant="outlined" className={classes.formControl}>
//       <InputLabel id={"select-product-label-" + (farmIdx)}>Farm {farmIdx}</InputLabel>
//       <Select
//         labelId={"select-product-label-" + (farmIdx)}
//         id={"select-product-" + (farmIdx)}
//         value={selectedPid[farmIdx-1]}
//         onChange={event => onChange(event, farmIdx-1)}
//         label={"Farm " + (farmIdx)}
//       >
//         <MenuItem value=""><em>None</em></MenuItem>
//         {plants.map(plant => (
//           <MenuItem value={plant.pid} key={"select-product-" + (plant.pid)}>{plant.name}</MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// }


// SelectProduct.propTypes = {
//   classes: PropTypes.any,
//   selectedPid: PropTypes.any,
//   onChange: PropTypes.func,
//   plants: PropTypes.any
// };


export default function ContractsTab() {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState();
  // const [plants, setPlants] = useState([]);
  const [contracts, setContracts] = useState([]);
  // const [selectedPid, setSelectedPid] = useState(['', '', '', '']);
  
  useEffect(() => {
    setIsLoaded(false);
    async function loadContracts() {
      await Contracts.getContracts()
        .then(
          (result) => {
            console.log(result);
            setContracts(result);
            setIsLoaded(true);
          },
          (error) => {
            setIsLoaded(true);
            setShowError(true);
            setError(error);
          }
        );
    }
    loadContracts();
  }, []);

  function handleSave() {
    Contracts.getContracts();
    // let farmConfig = farms.map(farm => ({
    //   farmId: farm.farmId,
    //   pid: parseInt(selectedPid[farm.farmId-1]),
    //   name: plants.find(plant => parseInt(plant.pid)===parseInt(selectedPid[farm.farmId-1])).name
    // }));
    //
    // Contracts.putFarms(farmConfig)
    //   .then(
    //     (_) => {
    //       setShowSuccess(true);
    //     },
    //     (error) => {
    //       setIsLoaded(true);
    //       setShowError(true);
    //       setError(error);
    //     }
    //   );
  }

  // const handleChange = (event, farm) => {
  //   selectedPid[farm] = parseInt(event.target.value);
  //   setSelectedPid([].concat(selectedPid));
  // };
  
  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <Grid container direction="column" justify="center" spacing={1} className={classes.mainGrid}>
        {/*<Grid key="alert" item>*/}
        {/*  <Collapse in={showSuccess}>*/}
        {/*    <Alert*/}
        {/*      severity="success"*/}
        {/*      className={classes.alertMsg}*/}
        {/*      onClose={() => {setShowSuccess(false)}}*/}
        {/*    >*/}
        {/*      <AlertTitle>Erfolg</AlertTitle>*/}
        {/*      Die Änderungen wurden erfolgreich an den Server übertragen.*/}
        {/*    </Alert>*/}
        {/*  </Collapse>*/}
        {/*  <Collapse in={showError}>*/}
        {/*    <Alert*/}
        {/*      severity="error"*/}
        {/*      className={classes.alertMsg}*/}
        {/*      onClose={() => {setShowError(false)}}*/}
        {/*    >*/}
        {/*      <AlertTitle>Fehler</AlertTitle>*/}
        {/*      Fehler: {error ? error.message: ""}*/}
        {/*    </Alert>*/}
        {/*  </Collapse>*/}
        {/*</Grid>*/}
        <Grid key="contracts" item>
          <Typography variant="h6">Available Contracts:</Typography>
          {Object.keys(contracts).map(user => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="user-content"
              id="user"
              >
              <Typography variant="subtitle2">{user}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                dense={true}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                {contracts[user].map(contract => (
                  <ListItem button>
                    <ListItemText
                      primary={`${contract.amount.toLocaleString("de-DE")} ${contract.name} für ${contract.price.toLocaleString("de-DE")} kT`}
                      secondary={`Verfügbar: ${(contract.inStock-contract.min).toLocaleString("de-DE")}, Marktpreis: ${contract.marketPrice.toLocaleString("de-DE")} kT`}
                    />
                    {contract.ready &&
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                          <CheckIcon/>
                        </IconButton>
                      </ListItemSecondaryAction>
                    }
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          ))}
        </Grid>
        {/*{Object.keys(contracts).map(user => (*/}
        {/*  <Grid key={user} item  xs={12}>*/}
        {/*    <Typography variant="h4" component="h4">{user}</Typography>*/}
        {/*    {contracts[user].map(contract => (*/}
        {/*      <Typography variant="body1" display="block" color={contract.ready ? "primary" : "error"} style={{paddingLeft:20}}>*/}
        {/*        {`${contract.name} ${contract.inStock-contract.min}/${contract.amount}`}*/}
        {/*      </Typography>*/}
        {/*    ))}*/}
        {/*    /!*<SelectProduct*!/*/}
        {/*    /!*  classes={classes}*!/*/}
        {/*    /!*  selectedPid={selectedPid}*!/*/}
        {/*    /!*  plants={plants}*!/*/}
        {/*    /!*  farmIdx = {farm.farmId}*!/*/}
        {/*    /!*  onChange={handleChange}*!/*/}
        {/*    />/*/}
        {/*  </Grid>*/}
        {/*))}*/}
        <Grid key="button-save" item>
          <Fab
            color="secondary"
            onClick={handleSave}
            className={classes.saveButton}
          >
            <SaveIcon />
          </Fab>
        </Grid>
      </Grid>
    );
  }
}
