import Rest from '../rest/Rest';


class Stock extends Rest {
  getOrders() {
    let restUrl = Rest.BaseUrl + '/stock/orders';
    const requestOptions = {
      headers: {'Authorization': `Bearer ${Rest.Token}`},
    };
    return fetch(restUrl, requestOptions).then(res => res.json());
  }
  
  
  putOrders(orders) {
    let restUrl = Rest.BaseUrl + '/stock/orders';
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Rest.Token}`
      },
      body: JSON.stringify(orders)
    };
    return fetch(restUrl, requestOptions)
  }
}


let stock = new Stock();
export default stock;
