import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Fab from "@material-ui/core/Fab";
import Collapse from '@material-ui/core/Collapse';
import { Alert, AlertTitle } from '@material-ui/lab';
import SaveIcon from "@material-ui/icons/Save";

import Farm from './Farm'
import Plants from '../rest/Plants'

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
  alertMsg: {
    marginTop: theme.spacing(0),
  },
}));


function SelectProduct(props) {
  const {classes, plants, farmIdx, selectedPid, onChange} = props;

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={"select-product-label-" + (farmIdx)}>Farm {farmIdx}</InputLabel>
      <Select
        labelId={"select-product-label-" + (farmIdx)}
        id={"select-product-" + (farmIdx)}
        value={selectedPid[farmIdx-1]}
        onChange={event => onChange(event, farmIdx-1)}
        label={"Farm " + (farmIdx)}
      >
        <MenuItem value=""><em>None</em></MenuItem>
        {plants.map(plant => (
          <MenuItem value={plant.pid} key={"select-product-" + (plant.pid)}>{plant.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}


SelectProduct.propTypes = {
  classes: PropTypes.any,
  selectedPid: PropTypes.any,
  onChange: PropTypes.func,
  plants: PropTypes.any
};


export default function FarmTab() {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState();
  const [plants, setPlants] = useState([]);
  const [farms, setFarms] = useState([]);
  const [selectedPid, setSelectedPid] = useState(['', '', '', '']);
  
  useEffect(() => {
    setIsLoaded(false);
    async function loadPlants() {
      await Plants.getPlants()
        .then(
          (result) => {
            let categories = ['v', 'ex', 'alpin'];
            let plants = result
              .filter(plant => plant && categories.includes(plant.category))
              .sort((p1, p2) => p1.name<=p2.name ? -1 : 1);
            setIsLoaded(true);
            setPlants(plants);
          },
          (error) => {
            setIsLoaded(true);
            setShowError(true);
            setError(error);
          }
        );
      await Farm.getFarms()
        .then(
          (result) => {
            let farms = result.sort((farm1, farm2) => farm1.farmId<=farm2.farmId ? -1 : 1);
            farms.map(farm => selectedPid[farm.farmId-1] = farm.pid);
            setIsLoaded(true);
            setFarms(farms);
            setSelectedPid(selectedPid);
          },
          (error) => {
            setIsLoaded(true);
            setShowError(true);
            setError(error);
          }
        );
    }
    loadPlants();
  }, []);

  const handleSave = () => {
    let farmConfig = farms.map(farm => ({
      farmId: farm.farmId,
      pid: parseInt(selectedPid[farm.farmId-1]),
      name: plants.find(plant => parseInt(plant.pid)===parseInt(selectedPid[farm.farmId-1])).name
    }));

    Farm.putFarms(farmConfig)
      .then(
        (_) => {
          setShowSuccess(true);
        },
        (error) => {
          setIsLoaded(true);
          setShowError(true);
          setError(error);
        }
      );
  }

  const handleChange = (event, farm) => {
    selectedPid[farm] = parseInt(event.target.value);
    setSelectedPid([].concat(selectedPid));
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <Grid container direction="column" justify="center" spacing={1} className={classes.mainGrid}>
        <Grid key="alert" item>
          <Collapse in={showSuccess}>
            <Alert
              severity="success"
              className={classes.alertMsg}
              onClose={() => {setShowSuccess(false)}}
            >
              <AlertTitle>Erfolg</AlertTitle>
              Die Änderungen wurden erfolgreich an den Server übertragen.
            </Alert>
          </Collapse>
          <Collapse in={showError}>
            <Alert
              severity="error"
              className={classes.alertMsg}
              onClose={() => {setShowError(false)}}
            >
              <AlertTitle>Fehler</AlertTitle>
              Fehler: {error ? error.message: ""}
            </Alert>
          </Collapse>
        </Grid>
      
        {farms.map(farm => (
          <Grid key={farm.farmId} item  xs={12}>
            <SelectProduct
              classes={classes}
              selectedPid={selectedPid}
              plants={plants}
              farmIdx = {farm.farmId}
              onChange={handleChange}
            />
          </Grid>
        ))}
        <Grid key="button-save" item>
          <Fab
            color="secondary"
            onClick={handleSave}
            className={classes.saveButton}
          >
            <SaveIcon />
          </Fab>
        </Grid>
      </Grid>
    );
  }
}
