import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {Fab, IconButton} from '@material-ui/core';
import {Collapse} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';

import {List, ListItem, ListItemText, ListItemSecondaryAction, Divider} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import Forestry from './Forestry'

import EditOrderDialog from "./EditOrderDialog";

function ListItemOrder(props) {
  const {products, order, onDelete, onEdit} = props;
  
  return (
    <ListItem button onClick={event => onEdit(event, order)}>
      <ListItemText
        primary={products.find(product => product.pid===order.pid).name}
        secondary={products.find(product => product.pid===order.pid).amount + '/' + order.amount}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="delete" onClick={event => onDelete(event, order)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}


ListItemOrder.propTypes = {
  classes: PropTypes.any,
  order: PropTypes.any,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  products: PropTypes.any
};


EditOrderDialog.propTypes = {
  products: PropTypes.any.isRequired,
  order: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
  },
  saveButton: {
    marginTop: theme.spacing(2),
  },
  alertMsg: {
    marginTop: theme.spacing(0),
  },
}));

export default function FoodworldTab() {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [editOrder, setEditOrder] = useState({});
  
  useEffect(() => {
    setIsLoaded(false);
    async function loadProducts() {
      await Forestry.getStock()
        .then(
          (result) => {
            let products = result
              .sort((p1, p2) => p1.name <= p2.name ? -1 : 1);
            setIsLoaded(true);
            setProducts(products);
          },
          (error) => {
            setIsLoaded(true);
            setShowError(true);
            setError(error);
          }
        );
      await Forestry.getOrders()
        .then(
          (result) => {
            let orderId = 0;
            let orders = result.map(order => {
              order.id = orderId++;
              return order
            });
            setIsLoaded(true);
            setOrders(orders);
          },
          (error) => {
            setIsLoaded(true);
            setShowError(true);
            setError(error);
          }
        );
    }
    loadProducts();
  }, []);
  
  const handleDelete = (_, order) => {
    let idx = orders.findIndex(o => o.id===order.id);
    if (idx!==-1) {
      orders.splice(idx, 1);
      setOrders(orders);
      setEditOrder(order);
    }
  }
  
  const handleEdit = (_, order) => {
    setShowEdit(true);
    setEditOrder(order);
  }
  
  const handleNew = () => {
    let id = orders.reduce((max, current) => max.id<current.id ? current : max).id + 1;
    let order = {pid: 0, amount: 0, id}
    setShowEdit(true);
    setEditOrder(order);
  }
  
  const handleEditOrderClose = (order) => {
    setShowEdit(false);
    if (!order) return;
    
    let idx = orders.findIndex(o => o.id===order.id);
    if (idx!==-1) {
      orders[idx].pid = order.pid;
      orders[idx].amount = order.amount;
      orders[idx].name = order.name;
    } else {
      orders.push(order);
    }
    setOrders(orders);
  }
  
  const handleSave = () => {
    let orderConfig = orders.map(order => ({
      pid: parseInt(order.pid),
      amount: order.amount,
      name: products.find(products => parseInt(products.pid)===parseInt(order.pid)).name
    }));
  
    Forestry.putOrders(orderConfig)
      .then(
        (_) => {
          setShowSuccess(true);
        },
        (error) => {
          setShowError(true);
          setError(error);
        }
      );
  }
  
  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <Grid container direction="column" justify="center" spacing={1} className={classes.mainGrid}>
        <Grid key="alert" item>
          <Collapse in={showSuccess}>
            <Alert
              severity="success"
              className={classes.alertMsg}
              onClose={() => {setShowSuccess(false)}}
            >
              <AlertTitle>Erfolg</AlertTitle>
              Die Änderungen wurden erfolgreich an den Server übertragen.
            </Alert>
          </Collapse>
          <Collapse in={showError}>
            <Alert
              severity="error"
              className={classes.alertMsg}
              onClose={() => {setShowError(false)}}
            >
              <AlertTitle>Fehler</AlertTitle>
              Fehler: {error ? error.message: ""}
            </Alert>
          </Collapse>
        </Grid>

        <Grid key="order-list" item>
          <List>
            {orders.map(order => (
              <div key={'div-list-'+order.id}>
                <ListItemOrder
                  classes={classes}
                  order={order}
                  products={products}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
                <Divider/>
              </div>
            ))}
          </List>
        </Grid>
        
        <Grid key="button-save" item>
          <Fab
            color="primary"
            onClick={handleNew}
            className={classes.addButton}
          >
            <AddIcon />
          </Fab>
          <Fab
            color="secondary"
            onClick={handleSave}
            className={classes.saveButton}
          >
            <SaveIcon />
          </Fab>
        </Grid>
        
        <EditOrderDialog
          open={showEdit}
          products={products}
          order={editOrder}
          onClose={handleEditOrderClose}
        />
      </Grid>
    );
  }
}
