import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import {Fab} from '@material-ui/core';
import {Slide} from '@material-ui/core';

import {Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import {FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import CancelIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';


const useStyles = makeStyles(theme => ({
  okButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  cancelButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  editControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function EditOrderDialog(props) {
  const classes = useStyles();
  const {products, order, onClose, open} = props;
  const [pid, setPid] = useState(0);
  const [amount, setAmount] = useState(0);
  
  // Initialize the state variables
  if (!open && pid!==0) {
    setPid(0);
    setAmount(0);
  } else if (open && pid===0 && order && order.pid!==0) {
    setPid(order.pid);
    setAmount(order.amount);
  }
  
  const handleOk = () => {
    let newOrder = order;
    newOrder.pid = parseInt(pid);
    newOrder.amount = parseInt(amount);
    newOrder.name = products.find(product => parseInt(product.pid)===parseInt(pid));
    onClose(newOrder);
  };
  
  const handleCancel = () => {
    onClose();
  };
  
  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="dialog-title"
      TransitionComponent={Transition}
      open={open}
      fullWidth={false}
      maxWidth={"md"}
    >
      <DialogTitle id="dialog-title">Auftrag bearbeiten</DialogTitle>
      <DialogContent>
      <Grid container justify="center" direction="column" alignItems="center" spacing={2}>
        <Grid key="edit" item>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-product-label">Produkt</InputLabel>
            <Select
              labelId="select-product-label"
              id="select-product"
              value={pid}
              onChange={event => setPid(event.target.value)}
              label={"Produkt"}
            >
              <MenuItem value={0} key="select-product-0"><em>None</em></MenuItem>
              {products.map(product => (
                <MenuItem value={product.pid} key={"select-product-" + (product.pid)}>{product.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid key="amount" item>
          <form className={classes.editControl} noValidate autoComplete="off">
            <TextField
              id="edit-amount"
              label="Menge"
              variant="outlined"
              value={amount}
              type="number"
              onChange={event => setAmount(event.target.value)}
            />
          </form>
        </Grid>
        <Grid key="save" item>
          <Fab color='secondary' className={classes.okButton} onClick={handleOk}><CheckIcon/></Fab>
          <Fab color='default' className={classes.cancelButton} onClick={handleCancel}><CancelIcon/></Fab>
        </Grid>
      </Grid>
      </DialogContent>
    </Dialog>
  );
}


EditOrderDialog.propTypes = {
  products: PropTypes.any.isRequired,
  order: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
