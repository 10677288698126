
class Rest {
  static init()  {
    return fetch('/local-config.json', {credentials: 'same-origin'}).then(function(response) {return response.json();});
  }

  static async login(user, password)  {
    let restUrl = Rest.BaseUrl + '/login';
    
    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({username:user, password:password})
    };
  
    Rest.Token = await fetch(restUrl, requestOptions)
      .then(function(response) {return response.json();})
      .then(
        (result) => {
          return result.accessToken;
        },
        (_) => {
          return 0;
        }
      );
  }

  static setHost(host) {
    Rest.BaseUrl = '/api/';
  }
}

Rest.BaseUrl = '';


export default Rest;
